import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import {
  GetParticipantDataFromFitbit,
  GetParticipantFitbitDataLocalDB,
} from '../participant/FitbitParticipantData';
import GraphicDisplayTile from '../../tiles/GraphicDisplayTile';
import DisplayDataChartTable from '../../displayData/DisplayDataChartTable';
import {
  prepareDateValueData,
  prepareSleepDurationData,
  prepareRestingHeartRateData,
} from '../displayData/FitbitPrepareDataForDisplay';
import MoveItems from '../../tiles/MoveItems';
import {timeRangeToStartDate} from '../../../services/date&time/DateTimeUtils';
import {exportMultipleTablesCSVEachInSeparateTab} from '../../table/ExportFileBlob';

import './DisplayParticipantOverviewData.css';

const DisplayParticipantOverviewData = ({participant, queryInfo}) => {
  const [participantData, setParticipantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [moveTileMode, setMoveTileMode] = useState('Shift');

  // Utility: difference between 7-day blocks
  const diffResults7Days = (subject, firstVal, secondVal) => {
    if (!subject || firstVal == null || secondVal == null) return '';
    let diff = 0;
    if (subject === 'Distance') {
      // Example: convert miles -> feet -> difference -> miles
      firstVal = firstVal * 5280;
      secondVal = secondVal * 5280;
      diff = (firstVal - secondVal) / 5280;
      diff = diff.toFixed(2);
    } else {
      diff = firstVal - secondVal;
    }

    if (diff === 0) {
      return {text: 'same as prev. 7 days', trend: 'same'};
    } else if (diff < 0) {
      return {text: `${Math.abs(diff)} below prev. 7 days`, trend: 'below'};
    } else {
      return {text: `${diff} over prev. 7 days`, trend: 'over'};
    }
  };

  // Fetch data from Fitbit & local DB
  useEffect(() => {
    let isMounted = true;

    const fetchData = async (queries, localDBRes) => {
      return Promise.all(
        queries.map(async (item, idx) => {
          let result = await GetParticipantDataFromFitbit(
            participant,
            item.query
          );
          if (result.errors) {
            result.subject = item.subject; // attach subject to display error
          } else {
            result.idx = idx;
            result.subject = item.subject;
            result.chartType = item.chart;

            // Add 7-day difference info from local DB if available
            if (result.subject === 'Resting Heart Rate' && result.data) {
              if (
                localDBRes?.data?.avrg_resting_heart_rate_last_7days != null
              ) {
                const diffInfo = diffResults7Days(
                  result.subject,
                  localDBRes.data.avrg_resting_heart_rate_last_7days,
                  localDBRes.data.avrg_resting_heart_rate_prev_7days
                );
                result.last7daysInfo = {
                  text: `Avg. last 7 days: ${
                    localDBRes.data.avrg_resting_heart_rate_last_7days
                  } bpm ${diffInfo.text ? `(${diffInfo.text})` : ''}`,
                  trend: diffInfo.trend || '',
                };
              }
              result = prepareRestingHeartRateData(result);
            } else if (result.subject === 'Sleep Duration' && result.data) {
              if (localDBRes?.data?.avrg_sleep_duration_last_7days != null) {
                const diffInfo = diffResults7Days(
                  result.subject,
                  localDBRes.data.avrg_sleep_duration_last_7days,
                  localDBRes.data.avrg_sleep_duration_prev_7days
                );
                result.last7daysInfo = {
                  text: `Avg. last 7 days: ${
                    localDBRes.data.avrg_sleep_duration_last_7days
                  } min. ${diffInfo.text ? `(${diffInfo.text})` : ''}`,
                  trend: diffInfo.trend || '',
                };
              }
              result = prepareSleepDurationData(result, 'overview');
            } else if (result.data) {
              if (result.subject === 'Steps') {
                if (localDBRes?.data?.total_steps_last_7days != null) {
                  const diffInfo = diffResults7Days(
                    result.subject,
                    localDBRes.data.total_steps_last_7days,
                    localDBRes.data.total_steps_prev_7days
                  );
                  result.last7daysInfo = {
                    text: `Total last 7 days: ${
                      localDBRes.data.total_steps_last_7days
                    } ${diffInfo.text ? `(${diffInfo.text})` : ''}`,
                    trend: diffInfo.trend || '',
                  };
                }
              }
              if (result.subject === 'Distance') {
                if (localDBRes?.data?.total_distance_last_7days != null) {
                  const diffInfo = diffResults7Days(
                    result.subject,
                    localDBRes.data.total_distance_last_7days,
                    localDBRes.data.total_distance_prev_7days
                  );
                  const last7DaysVal =
                    localDBRes.data.total_distance_last_7days.toFixed(2);
                  result.last7daysInfo = {
                    text: `Total last 7 days: ${last7DaysVal} Miles ${
                      diffInfo.text ? `(${diffInfo.text})` : ''
                    }`,
                    trend: diffInfo.trend || '',
                  };
                }
              }
              if (result.subject === 'Weight') {
                if (localDBRes?.data?.avrg_weight_last_7days != null) {
                  const diffInfo = diffResults7Days(
                    result.subject,
                    localDBRes.data.avrg_weight_last_7days,
                    localDBRes.data.avrg_weight_prev_7days
                  );
                  result.last7daysInfo = {
                    text: `Avg. last 7 days: ${
                      localDBRes.data.avrg_weight_last_7days
                    } lbs ${diffInfo.text ? `(${diffInfo.text})` : ''}`,
                    trend: diffInfo.trend || '',
                  };
                }
              }
              if (result.subject === 'Floors') {
                if (localDBRes?.data?.total_floors_last_7days != null) {
                  const diffInfo = diffResults7Days(
                    result.subject,
                    localDBRes.data.total_floors_last_7days,
                    localDBRes.data.total_floors_prev_7days
                  );
                  result.last7daysInfo = {
                    text: `Total last 7 days: ${
                      localDBRes.data.total_floors_last_7days
                    } ${diffInfo.text ? `(${diffInfo.text})` : ''}`,
                    trend: diffInfo.trend || '',
                  };
                }
              }
              // Prepare for chart & table display
              result = prepareDateValueData(result);
            }
          }
          return result;
        })
      );
    };

    const getParticipantData = async () => {
      if (!participant || !queryInfo) return;
      try {
        setLoading(true);

        // Decide date range
        let date1, date2;
        let sleepDate1, sleepDate2;
        const isInitial = queryInfo === 'initialDataRequest';

        if (isInitial) {
          date2 = DateTime.now().toISODate();
          date1 = DateTime.now().minus({days: 6}).toISODate();
        } else {
          if (queryInfo.period && queryInfo.period !== 'customDates') {
            date1 = DateTime.now();
            date2 = queryInfo.period;

            // For Sleep, convert 'period' -> explicit date range
            sleepDate1 = timeRangeToStartDate(queryInfo.period, date1);
            sleepDate2 = date1.toISODate();
            date1 = date1.toISODate();
          } else if (queryInfo.startDate && queryInfo.endDate) {
            date1 = DateTime.fromJSDate(queryInfo.startDate).toFormat(
              'yyyy-MM-dd'
            );
            date2 = DateTime.fromJSDate(queryInfo.endDate).toFormat(
              'yyyy-MM-dd'
            );
          } else {
            console.error('Overview is missing a period');
            throw new Error('Please select a period');
          }
        }

        const queries = [
          {
            subject: 'Steps',
            query: (startDate, endDate) =>
              `/activities/steps/date/${startDate}/${endDate}.json`,
            chart: 'LineChart',
          },
          {
            subject: 'Distance',
            query: (startDate, endDate) =>
              `/activities/distance/date/${startDate}/${endDate}.json`,
            chart: 'BarChart',
          },
          {
            subject: 'Floors',
            query: (startDate, endDate) =>
              `/activities/floors/date/${startDate}/${endDate}.json`,
            chart: 'BarChart',
          },
          {
            subject: 'Resting Heart Rate',
            query: (startDate, endDate) =>
              `/activities/heart/date/${startDate}/${endDate}.json`,
            chart: 'LineChart',
          },
          {
            subject: 'Sleep Duration',
            query: (startDate, endDate) =>
              `/sleep/date/${startDate}/${endDate}.json`,
            chart: 'ComposedBarLineChart',
          },
          {
            subject: 'Weight',
            query: (startDate, endDate) =>
              `/body/weight/date/${startDate}/${endDate}.json`,
            chart: 'LineChart',
          },
        ];

        const localDBRes = await GetParticipantFitbitDataLocalDB(participant);

        const queriesWithDates = queries.map((q) => ({
          ...q,
          query:
            q.subject === 'Sleep Duration'
              ? q.query(sleepDate1 || date1, sleepDate2 || date2)
              : q.query(date1, date2),
        }));

        // Fetch data
        const results = await fetchData(queriesWithDates, localDBRes);
        if (isMounted) {
          setParticipantData(results);
        }
      } catch (error) {
        console.error('Error fetching participant data:', error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    getParticipantData();

    return () => {
      isMounted = false;
    };
  }, [participant, queryInfo]);

  // Move or swap tiles
  const swapTiles = (fromTile, toTile) => {
    const tiles = [...participantData];
    const fromIndex = tiles.findIndex((tile) => tile.idx === fromTile.idx);
    const toIndex = tiles.findIndex((tile) => tile.idx === toTile.idx);
    if (fromIndex !== -1 && toIndex !== -1) {
      const temp = tiles[fromIndex];
      tiles[fromIndex] = {...tiles[toIndex], idx: tiles[fromIndex].idx};
      tiles[toIndex] = {...temp, idx: tiles[toIndex].idx};
      setParticipantData(tiles);
    }
  };

  const shiftTileRight = (fromTile, toTile) => {
    let tiles = [...participantData];
    const fromIndex = tiles.findIndex((tile) => tile.idx === fromTile.idx);
    const toIndex = tiles.findIndex((tile) => tile.idx === toTile.idx);
    if (fromIndex !== -1 && toIndex !== -1) {
      const [movedTile] = tiles.splice(fromIndex, 1);
      tiles.splice(toIndex, 0, movedTile);
      // Re-index
      tiles = tiles.map((tile, index) => ({...tile, idx: index}));
      setParticipantData(tiles);
    }
  };

  const moveTile = (fromTile, toTile) => {
    if (moveTileMode === 'Shift') {
      shiftTileRight(fromTile, toTile);
    } else {
      swapTiles(fromTile, toTile);
    }
  };

  // Select/deselect all
  useEffect(() => {
    if (participantData?.length > 0) {
      const allSubjects = participantData.map((activity) => activity.subject);
      setAllSelected(allSubjects.length === selectedActivities.length);
    }
  }, [selectedActivities, participantData]);

  const toggleSelectAllTiles = () => {
    if (!participantData || participantData.length === 0) return;
    if (allSelected) {
      setSelectedActivities([]);
    } else {
      const allSubjects = participantData.map((activity) => activity.subject);
      setSelectedActivities(allSubjects);
    }
    setAllSelected(!allSelected);
  };

  const handleCheckboxChange = (subject) => {
    setSelectedActivities((prev) =>
      prev.includes(subject)
        ? prev.filter((item) => item !== subject)
        : [...prev, subject]
    );
  };

  // Update the handleExportDataCSV function
  const handleExportDataCSV = async () => {
    try {
      if (!participantData || selectedActivities.length === 0) {
        console.error('No data selected for export');
        return;
      }

      const fileName = 'CDConnect-Fitbit-Data.xlsx';
      await exportMultipleTablesCSVEachInSeparateTab(
        participantData,
        selectedActivities,
        fileName
      );
    } catch (error) {
      console.error('Export Error:', error);
    }
  };

  return (
    <div className="overview-data">
      {loading && <div className="spinnerModal"></div>}

      <div className="button-container">
        <button type="button" className="btn" onClick={toggleSelectAllTiles}>
          {allSelected ? 'Deselect All Activities' : 'Select All Activities'}
        </button>

        {selectedActivities.length > 0 && (
          <button type="button" className="btn" onClick={handleExportDataCSV}>
            Export Selected Data
          </button>
        )}

        <select
          className="select-menu-short select-dropdown"
          value={moveTileMode}
          onChange={(e) => setMoveTileMode(e.target.value)}
        >
          <option value="Shift">Tiles Shift Mode</option>
          <option value="Swap">Tiles Swap Mode</option>
        </select>
      </div>

      <div className="flex-container">
        {participantData?.length > 0 &&
          participantData.map((item, index) => (
            <div key={index} className="draggable-tile flex-item">
              <MoveItems
                id={index}
                draggable="true"
                item={item}
                moveItemsCallback={moveTile}
              >
                <GraphicDisplayTile
                  info={item}
                  isSelected={selectedActivities.includes(item.subject)}
                  onSelect={() => handleCheckboxChange(item.subject)}
                >
                  <DisplayDataChartTable
                    deviceName={'Fitbit'}
                    subject={item.subject}
                    content={item}
                  />
                </GraphicDisplayTile>
              </MoveItems>
            </div>
          ))}
      </div>
    </div>
  );
};

DisplayParticipantOverviewData.propTypes = {
  participant: PropTypes.object,
  queryInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default DisplayParticipantOverviewData;
