// ExportFileBlob.jsx
import ExcelJS from 'exceljs';

/**
 * Export data as CSV (simple approach).
 * Replaces NaN with 0, undefined with ''.
 */
const exportDataCSV = (columns, data, fileName) => {
  // Prepare headers
  const headers = columns.map((col) => col.Header);

  // Prepare rows, replacing NaN with 0
  const rows = data.map((row) =>
    columns.map((col) => {
      const val = row[col.accessor];
      if (Number.isNaN(val)) {
        return 0;
      } else {
        return val !== undefined ? val : '';
      }
    })
  );

  // Build the CSV string
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += headers.join(',') + '\n';
  csvContent += rows.map((r) => r.join(',')).join('\n');

  // Create a link and auto-click to download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Export data as single-sheet XLSX (Excel).
 * Replaces NaN with 0, undefined with ''.
 */
const exportDataXLSX = async (columns, data, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);

  // Add header row
  const headers = columns.map((col) => col.Header);
  worksheet.addRow(headers);

  // Add data rows, replacing NaN with 0
  data.forEach((row) => {
    const rowData = columns.map((col) => {
      const val = row[col.accessor];
      if (Number.isNaN(val)) {
        return 0;
      }
      return val !== undefined ? val : '';
    });
    worksheet.addRow(rowData);
  });

  // Convert workbook to buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Create a Blob and download via object URL
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

/**
 * Exports Fitbit data to Excel with multiple sheets
 * @param {Array} activities - Array of activity data
 * @param {Array} selectedSubjects - Array of selected activity subjects
 * @param {string} fileName - Name for the output file
 */
const exportMultipleTablesCSVEachInSeparateTab = async (
  activities,
  selectedSubjects,
  fileName
) => {
  try {
    const workbook = new ExcelJS.Workbook();

    // Create a worksheet for each selected activity
    selectedSubjects.forEach((subject) => {
      const activity = activities.find((act) => act.subject === subject);
      if (!activity) return;

      const worksheet = workbook.addWorksheet(subject);

      // Determine data source and structure
      if (activity.chartData && Array.isArray(activity.chartData)) {
        // Add headers based on activity type
        let headers = ['Date', 'Value'];
        if (subject === 'Sleep Duration') {
          headers = ['Date', 'Duration (minutes)', 'Efficiency'];
        } else if (subject === 'Resting Heart Rate') {
          headers = ['Date', 'BPM'];
        } else {
          headers = ['Date', subject];
        }
        worksheet.addRow(headers);

        // Add data rows
        activity.chartData.forEach((item) => {
          let rowData = [item.date];
          if (subject === 'Sleep Duration') {
            const duration = Number.isNaN(item.duration)
              ? 0
              : item.duration || 0;
            const efficiency = Number.isNaN(item.efficiency)
              ? 0
              : item.efficiency || 0;
            rowData.push(duration, efficiency);
          } else if (subject === 'Resting Heart Rate') {
            const heartRate = Number.isNaN(item.restingHeartRate)
              ? 0
              : item.restingHeartRate || 0;
            rowData.push(heartRate);
          } else {
            const value = Number.isNaN(item.value) ? 0 : item.value || 0;
            rowData.push(value);
          }
          worksheet.addRow(rowData);
        });
      } else if (activity.data && Array.isArray(activity.data)) {
        // Handle traditional data format
        if (activity.columns) {
          // Add headers from columns
          const headers = activity.columns.map((col) => col.Header);
          worksheet.addRow(headers);

          // Add data rows
          activity.data.forEach((row) => {
            const rowData = activity.columns.map((col) => {
              const val = row[col.accessor];
              if (Number.isNaN(val)) {
                return 0;
              }
              return val !== undefined ? val : '';
            });
            worksheet.addRow(rowData);
          });
        } else {
          // Fallback for simple data
          worksheet.addRow(['Date', 'Value']);
          activity.data.forEach((item) => {
            const value = Number.isNaN(item.value) ? 0 : item.value || 0;
            worksheet.addRow([item.date || item.dateTime, value]);
          });
        }
      }

      // Format columns for better readability
      worksheet.columns.forEach((column) => {
        column.width = 15;
      });
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create blob and download
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'CDConnect-Fitbit-Data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    console.error('Error exporting to Excel:', error);
    throw error;
  }
};

export {
  exportDataCSV,
  exportDataXLSX,
  exportMultipleTablesCSVEachInSeparateTab,
};
