import React from 'react';
import {Route, Routes} from 'react-router-dom';

import Dashboard from './components/dashboard/Dashboard';
import FitbitCallback from './components/fitbit/participant/fitbit-callback';
import FitbitParticipantConsent from './components/fitbit/participant/FitbitParticipantConsent';
import TrialPage from './components/trials/TrialPage';
import ParticipantDataPage from './components/participant/ParticipantDataPage';
import FitbitParticipantDataPage from './components/fitbit/participant/FitbitParticipantDataPage';
import OmronCallback from './components/omron/participant/omron-callback';
import OmronConsentPage from './components/omron/participant/OmronParticipantConsent';
import ContactUsForm from './components/home/ContactUsForm';
// import TermsAndConditions from './components/documents/TermsAndConditions';
// import PrivacyPolicy from './components/documents/PrivacyPolicy';
import SignupPage from './components/authorization/SignupPage';
import LoginPage from './components/authorization/LoginPage';
import SFASetupPage from './components/authorization/SFASetupPage';
import SFAVerifyPage from './components/authorization/SFAVerifyPage';
import SFAValidatePage from './components/authorization/SFAValidatePage';
import SFASendCodeViaMsgPage from './components/authorization/SFASendCodeViaMsgPage';
import SignUpApprovalPage from './components/authorization/SignupApprovalPage';
import SignupValidation from './components/authorization/SignupValidation';
import UserTeams from './components/teams/UserTeams';
import Trials from './components/trials/Trials';
import TrialUsers from './components/trialUsers/TrialUsers';
import ParticipantsPage from './components/participant/ParticipantsPage';
import ParticipantSignupPage from './components/participantAccess/ParticipantSignupPage';
import {ProtectedRoute} from './components/authorization/ProtectedRoute';
import ParticipantManagement from './components/participant/ParticipantManagement';
import FitbitParticipantDataForm from './components/fitbit/participant/FitbitParticipantDataForm';
import ParticipantRaw from './components/participant/ParticipantRaw';
import FitbitParticipantDataRaw from './components/fitbit/participant/FitbitParticipantDataRaw';
import OmronParticipantDataRaw from './components/omron/participant/OmronParticipantDataRaw';
import OmronParticipantDataPage from './components/omron/participant/OmronParticipantDataPage';
import OmronManageParticipantToken from './components/omron/participant/OmronManageParticipantToken';
import ParticipantsDataPage from './components/participants/ParticipantsDataPage';
import ParticipantOverview from './components/participant/ParticipantOverview';
import FitbitParticipantOverview from './components/fitbit/participant/FitbitParticipantOverview';
import OmronParticipantOverview from './components/omron/participant/OmronParticipantOverview';
import FitbitManageParticipantToken from './components/fitbit/participant/FitbitManageParticipantToken';
import AddParticipantPage from './components/participant/newParticipant/AddParticipantPage';
import PAFitbitConsentPage from './components/participantAccess/Fitbit//PAFitbitConsentPage';
import ChangePwdReqPage from './components/authorization/ChangePwdReqPage';
import ResetPwdReqPage from './components/authorization/ResetPwdReqPage';
import ResetPasswordPage from './components/authorization/ResetPasswordPage';
import FitbitParticipant1DayData from './components/fitbit/participant/FitbitParticipant1DayData';
import FitbitParticipantsDataPage from './components/fitbit/participants/FitbitParticipantsDataPage';
import DexcomCallback from './components/dexcom/participant/dexcom-callback';
import DexcomParticipantConsent from './components/dexcom/participant/DexcomParticipantConsent';
import DexcomParticipantDataRaw from './components/dexcom/participant/DexcomParticipantDataRaw';
import PageNotFound from './components/errorHandling/PageNotFound';
import NotAuthorizedPage from './components/errorHandling/NotAuthorizedPage';
import DbInfoPage from './components/sysDirector/dbInfo/DbInfoPage';
import DataStorageService from './components/dataStorageAndManagement/DataStorageService';
import FitbitDataPreferences from './components/fitbit/dataStorageAndManagement/FitbitDataPreferences';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Start - Unprotected Routes */}
      <Route path="/SignupPage" element={<SignupPage />} />
      <Route path="/LoginPage" element={<LoginPage />} />
      <Route
        path="/ParticipantSignupPage"
        element={<ParticipantSignupPage />}
      />
      <Route path="/fitbit-callback" element={<FitbitCallback />} />
      <Route path="/omron-callback" element={<OmronCallback />} />
      <Route path="/dexcom-callback" element={<DexcomCallback />} />
      <Route path="/PAFitbitConsentPage" element={<PAFitbitConsentPage />} />
      <Route exact path="/OmronConsentPage" element={<OmronConsentPage />} />

      <Route path="/ResetPwdReqPage" element={<ResetPwdReqPage />} />
      <Route path="/ResetPasswordPage" element={<ResetPasswordPage />} />
      <Route path="/ContactUsForm" element={<ContactUsForm />} />
      <Route exact path="/SignupValidation" element={<SignupValidation />} />
      {/* <Route path="/TermsAndConditions" element={<TermsAndConditions />} /> */}
      {/* <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> */}
      {/* End - Unprotected Routes */}

      {/* Start - Protected Routes with allowed roles */}
      {/* Keep in sync with the server roles!!! */}

      {/* Start - 'Sys_Director', 'Admin', 'User', 'Viewer' */}
      <Route
        element={
          <ProtectedRoute
            allowedRoles={['Sys_Director', 'Admin', 'User', 'Viewer']}
          />
        }
      >
        <Route exact path="/SFASetupPage" element={<SFASetupPage />} />
        <Route exact path="/SFAVerifyPage" element={<SFAVerifyPage />} />
        <Route exact path="/SFAValidatePage" element={<SFAValidatePage />} />
        <Route
          exact
          path="/SFASendCodeViaMsgPage"
          element={<SFASendCodeViaMsgPage />}
        />

        <Route path="/" element={<Dashboard />} />
        <Route exact path="/UserTeams" element={<UserTeams />} />
        <Route exact path="/Trials" element={<Trials />} />
        <Route exact path="/TrialPage" element={<TrialPage />} />
        <Route
          exact
          path="/FitbitParticipantDataPage"
          element={<FitbitParticipantDataPage />}
        />
        <Route
          exact
          path="/ParticipantsDataPage"
          element={<ParticipantsDataPage />}
        />
        <Route
          exact
          path="/FitbitParticipantOverview"
          element={<FitbitParticipantOverview />}
        />
        <Route exact path="/ParticipantsPage" element={<ParticipantsPage />} />
        <Route
          exact
          path="/AddParticipantPage"
          element={<AddParticipantPage />}
        />
        <Route
          exact
          path="/FitbitParticipantDataForm"
          element={<FitbitParticipantDataForm />}
        />
        <Route
          exact
          path="/FitbitParticipant1DayData"
          element={<FitbitParticipant1DayData />}
        />
        <Route
          exact
          path="/FitbitParticipantsDataPage"
          element={<FitbitParticipantsDataPage />}
        />
        <Route
          exact
          path="/OmronParticipantDataPage"
          element={<OmronParticipantDataPage />}
        />
        <Route
          exact
          path="/ParticipantDataPage"
          element={<ParticipantDataPage />}
        />
        <Route
          exact
          path="/ParticipantOverview"
          element={<ParticipantOverview />}
        />
        <Route
          exact
          path="/OmronParticipantOverview"
          element={<OmronParticipantOverview />}
        />
        <Route exact path="/ChangePwdReqPage" element={<ChangePwdReqPage />} />
      </Route>
      {/* End - 'Sys_Director', 'Admin', 'User', 'Viewer' */}

      {/* Start - 'Sys_Director', 'Admin', 'User' */}
      <Route
        element={
          <ProtectedRoute allowedRoles={['Sys_Director', 'Admin', 'User']} />
        }
      >
        <Route exact path="/Participant" element={<ParticipantManagement />} />
        <Route
          exact
          path="/FitbitParticipantConsent"
          element={<FitbitParticipantConsent />}
        />
        <Route
          exact
          path="/DexcomParticipantConsent"
          element={<DexcomParticipantConsent />}
        />
      </Route>
      {/* End - 'Sys_Director', 'Admin', 'User' */}

      {/* Start - 'Sys_Director', 'Admin' */}
      <Route
        element={<ProtectedRoute allowedRoles={['Sys_Director', 'Admin']} />}
      >
        <Route
          exact
          path="/FitbitManageParticipantToken"
          element={<FitbitManageParticipantToken />}
        />
        <Route
          exact
          path="/OmronManageParticipantToken"
          element={<OmronManageParticipantToken />}
        />
        <Route exact path="/TrialUsers" element={<TrialUsers />} />
        <Route
          exact
          path="/DataStorageService"
          element={<DataStorageService />}
        />
        <Route
          exact
          path="/FitbitDataPreferences/:teamId"
          element={<FitbitDataPreferences />}
        />
      </Route>
      {/* End - 'Sys_Director', 'Admin' */}

      {/* Start - 'Sys_Director' */}
      <Route element={<ProtectedRoute allowedRoles={['Sys_Director']} />}>
        <Route
          exact
          path="/SignUpApprovalPage"
          element={<SignUpApprovalPage />}
        />
        <Route exact path="/ParticipantRaw" element={<ParticipantRaw />} />
        <Route
          exact
          path="/FitbitParticipantDataRaw"
          element={<FitbitParticipantDataRaw />}
        />
        <Route
          exact
          path="/OmronParticipantDataRaw"
          element={<OmronParticipantDataRaw />}
        />
        <Route
          exact
          path="/DexcomParticipantDataRaw"
          element={<DexcomParticipantDataRaw />}
        />
        <Route exact path="/DbInfoPage" element={<DbInfoPage />} />
      </Route>
      {/* End - 'Sys_Director' */}
      {/* End - Protected Routes with allowed roles */}

      {/* Roles Fallback Route */}
      <Route path="/not-authorized" element={<NotAuthorizedPage />} />
      {/* Page not found. Keep last!!! */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
