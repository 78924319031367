import React from 'react';
import PropTypes from 'prop-types';

import {useFitbitDataPreferences} from './useFitbitDataPreferences';
import {useParams} from 'react-router-dom';
import {DisplayErrors} from '../../errorHandling/ErrorsDisplay';

import './FitbitDataPreferences.css';

const FitbitDataPreferences = () => {
  const {teamId} = useParams();
  const {
    preferences,
    availableDataTypes,
    handleCheckboxChange,
    savePreferences,
    loading,
    error,
    successMessage, // get the success message from the hook
  } = useFitbitDataPreferences(teamId);

  if (loading) {
    return <div className="spinnerModal"></div>;
  }
  if (error) {
    return <DisplayErrors errors={`Error:${error}`} />;
  }

  // Determine if every available data type is currently selected
  const allSelected = availableDataTypes.every((dataType) =>
    preferences.includes(dataType)
  );

  // Toggle select all or deselect all based on the current selection state
  const toggleSelectAll = () => {
    if (allSelected) {
      availableDataTypes.forEach((dataType) => {
        if (preferences.includes(dataType)) {
          handleCheckboxChange(dataType);
        }
      });
    } else {
      availableDataTypes.forEach((dataType) => {
        if (!preferences.includes(dataType)) {
          handleCheckboxChange(dataType);
        }
      });
    }
  };

  return (
    <div className="container">
      <h1>Data Storage Preferences</h1>
      <p>
        Please select one or more of the following data types to specify which
        data should be stored in our system for retention purposes.
      </p>
      <p>
        The data for options already selected is stored in the CDConnect data
        retention system. If you deselect an option, any future data will no
        longer be retained in our system. However, data that has already been
        stored will remain in our system.
      </p>

      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      <div className="data-storage-select-menu">
        <label>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={toggleSelectAll}
          />
          Select All
        </label>
      </div>

      {availableDataTypes.map((dataType) => (
        <div key={dataType} className="checkbox-row">
          <label>
            <input
              type="checkbox"
              checked={preferences.includes(dataType)}
              onChange={() => handleCheckboxChange(dataType)}
            />
            {dataType}
          </label>
        </div>
      ))}

      <button className="btn" onClick={savePreferences}>
        Save Preferences
      </button>
    </div>
  );
};

FitbitDataPreferences.propTypes = {
  teamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default FitbitDataPreferences;
