import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {fetchApiGet} from '../api/fetchApi';
import {DisplayErrors} from '../errorHandling/ErrorsDisplay';
import {useAuth} from '../authorization/AuthContext';

import './DataStorageService.css';

const DataStorageService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {userRole} = useAuth();

  // Redirect if not authorized
  useEffect(() => {
    if (userRole !== 'Sys_Director' /* && userRole !== 'Admin' */) {
      navigate('/not-authorized');
      return;
    }
  }, [userRole, navigate]);

  // Expect teamId to be passed in location.state
  const {teamId} = location.state || {};

  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [devicesError, setDevicesError] = useState(null);
  const [preferenceErrMessage, setPreferenceErrMessage] = useState('');

  useEffect(() => {
    if (teamId) {
      fetchApiGet(`/teamSupportedDevices/${teamId}`)
        .then((data) => {
          setDevices(data);
          setLoadingDevices(false);
        })
        .catch((error) => {
          console.error('Error fetching devices:', error);
          setDevicesError(error);
          setLoadingDevices(false);
        });
    } else {
      setLoadingDevices(false);
    }
  }, [teamId]);

  // Determines which route to navigate to based on the device
  const handleDevicePreference = (device) => {
    setPreferenceErrMessage('');
    const deviceName = device.device_name.toLowerCase();
    switch (deviceName) {
      case 'fitbit':
      case 'fitbit smartwatches & trackers':
        navigate(`/fitbitDataPreferences/${teamId}`);
        break;
      default:
        setPreferenceErrMessage(
          `Error: we could not find any data storage service for: ${device.device_name}.`
        );
        break;
    }
  };

  return (
    <div>
      <h1>Data Storage Service</h1>
      {loadingDevices ? (
        <div className="spinnerModal"></div>
      ) : devicesError ? (
        <DisplayErrors
          errors={`Error loading devices:${devicesError.message}`}
        />
      ) : (
        <>
          {preferenceErrMessage && (
            <div className="error-container">
              <DisplayErrors errors={preferenceErrMessage} />
              <button
                onClick={() => setPreferenceErrMessage('')}
                className="error-close-btn"
                aria-label="Close error message"
              >
                &times;
              </button>
            </div>
          )}
          <p>The following devices are supported for your team:</p>
          {devices.length > 0 ? (
            <>
              <p>Please select a device below to configure its data storage.</p>
              <table>
                <thead>
                  <tr>
                    <th>Device Name</th>
                  </tr>
                </thead>
                <tbody>
                  {devices.map((device) => (
                    <tr
                      key={device.id}
                      onClick={() => handleDevicePreference(device)}
                      style={{cursor: 'pointer'}}
                    >
                      <td>{device.device_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="empty-message">
              <p>No supported devices found.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DataStorageService;
