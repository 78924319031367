import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Table from '../../table/Table';

import {fetchApiGet} from '../../api/fetchApi';

// Constants for API endpoints
const API_ENDPOINTS = {
  users: '/sysDirectorUsersData',
  trials: '/sysDirectorTrialsData',
  participants: '/sysDirectorParticipantsData',
  teams: '/sysDirectorTeamsData',
  teamSupportedDevices: '/sysDirectorTeamSupportedDevicesData',
  teamDataStorageSettings: '/sysDirectorTeamDataStorageSettings',
};

// Generic Table Component
function DataTable({title, endpoint, columns}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use the custom fetch function instead of fetch()
        const result = await fetchApiGet(endpoint);
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  if (loading) return <p>Loading {title}...</p>;
  if (error)
    return (
      <p>
        Error loading {title}: {error}
      </p>
    );

  return (
    <div>
      <h2>{title}</h2>
      <Table
        data={data}
        columns={columns}
        features={{
          singleRowSelection: false,
          multipleRowsSelection: false,
          maxNumOfSelectedRows: null,
          displayGlobalFilter: true,
          pageSize: 50,
        }}
      />
    </div>
  );
}

// PropTypes for DataTable Component
DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      id: PropTypes.string,
    })
  ).isRequired,
};

function DbInfoPage() {
  // Column Definitions
  const userColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'User ID', accessor: 'user_id'},
      {Header: 'First Name', accessor: 'first_name'},
      {Header: 'Last Name', accessor: 'last_name'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Role', accessor: 'role'},
      {Header: 'Team Name', accessor: 'team_name'},
      {Header: 'SFA Required', accessor: 'sfa_required'},
      {Header: 'SFA Enabled', accessor: 'sfa_enabled'},
      {Header: 'SFA Type', accessor: 'sfa_type'},
      {Header: 'Create Time', accessor: 'create_time'},
      {Header: 'Organization', accessor: 'organization'},
    ],
    []
  );

  const trialColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'Trial ID', accessor: 'trial_id'},
      {Header: 'Trial Name', accessor: 'trial_name'},
      {Header: 'Trial Number', accessor: 'trial_number'},
      {Header: 'Trial Notes', accessor: 'trial_notes'},
      {Header: 'Owner Email', accessor: 'owner_email'},
      {Header: 'Team ID', accessor: 'the_team_id'},
      {Header: 'Team Name', accessor: 'team_name'},
      {Header: 'Creation Date', accessor: 'creation_date'},
    ],
    []
  );

  const participantColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'Participant ID', accessor: 'participant_id'},
      {Header: 'Team ID', accessor: 'the_team_id'},
      {Header: 'Team Name', accessor: 'team_name'},
      {Header: 'Participant Email', accessor: 'email'},
      {Header: 'Gender', accessor: 'gender'},
      {Header: 'Age', accessor: 'age'},
      {Header: 'Last Update', accessor: 'last_update'},
      {Header: 'Creation Time', accessor: 'create_time'},
    ],
    []
  );

  const teamColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'Team ID', accessor: 'team_id'},
      {Header: 'Team Name', accessor: 'team_name'},
    ],
    []
  );

  const teamSupportedDevicesColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'ID', accessor: 'id'},
      {Header: 'Team ID', accessor: 'team_id'},
      {Header: 'Team Name(*)', accessor: 'team_name'},
      {Header: 'Global Device ID', accessor: 'global_device_id'},
      {Header: 'Device Name(*)', accessor: 'device_name'},
      {Header: 'Is Active', accessor: 'is_active'},
      {Header: 'Notes', accessor: 'notes'},
    ],
    []
  );

  const teamDataStorageSettingsColumns = useMemo(
    () => [
      {Header: 'Num', accessor: (_, index) => index + 1, id: 'rowNumber'},
      {Header: 'ID', accessor: 'id'},
      {Header: 'Team ID(*)', accessor: 'team_id'},
      {Header: 'Team Name(*)', accessor: 'team_name'},
      {
        Header: 'team_supported_device_id',
        accessor: 'team_supported_device_id',
      },
      {Header: 'Metric Name', accessor: 'metric_name'},
      {Header: 'Is Enabled', accessor: 'is_enabled'},
      {Header: 'Device Metrics', accessor: 'device_metrics'},
    ],
    []
  );

  return (
    <>
      <h1>Sys Director Info Page</h1>
      <DataTable
        title="User Table"
        endpoint={API_ENDPOINTS.users}
        columns={userColumns}
      />
      <DataTable
        title="Trial Table"
        endpoint={API_ENDPOINTS.trials}
        columns={trialColumns}
      />
      <DataTable
        title="Participant Table"
        endpoint={API_ENDPOINTS.participants}
        columns={participantColumns}
      />
      <DataTable
        title="Team Table"
        endpoint={API_ENDPOINTS.teams}
        columns={teamColumns}
      />
      <DataTable
        title="Team Supported Devices Table"
        endpoint={API_ENDPOINTS.teamSupportedDevices}
        columns={teamSupportedDevicesColumns}
      />
      <DataTable
        title="Team Devices Data Storage Settings Table"
        endpoint={API_ENDPOINTS.teamDataStorageSettings}
        columns={teamDataStorageSettingsColumns}
      />
    </>
  );
}

export default DbInfoPage;
