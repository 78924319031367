import {useState, useEffect} from 'react';

import {fetchApiSet, fetchApiGet} from '../../api/fetchApi';

export const useFitbitDataPreferences = (teamId) => {
  console.log(`teamId: ${teamId}`);
  const [preferences, setPreferences] = useState([]);
  const [availableDataTypes, setAvailableDataTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const url = `/teamDataStorageSettings/${teamId}`;
        const res = await fetchApiGet(url);
        // console.log(res);
        if (
          res &&
          Array.isArray(res.preferences) &&
          Array.isArray(res.availableDataTypes) &&
          res.availableDataTypes.length > 0
        ) {
          // Valid data: set state
          setPreferences(res.preferences);
          setAvailableDataTypes(res.availableDataTypes);
        } else {
          // Invalid or empty data: set error
          console.error('Invalid or empty response from server:', res);
          setError('Data not found.');
          setPreferences([]); // Default to empty
          setAvailableDataTypes([]); // Default to empty
        }
      } catch (err) {
        console.error('Error fetching preferences:', err);
        setError('Data not found.');
      } finally {
        setLoading(false);
      }
    };

    fetchPreferences();
  }, [teamId]);

  const handleCheckboxChange = (dataType) => {
    setPreferences((prev) =>
      prev.includes(dataType)
        ? prev.filter((type) => type !== dataType)
        : [...prev, dataType]
    );
  };

  const savePreferences = async () => {
    try {
      const res = await fetchApiSet(`/teamDataStorageSettings/${teamId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({preferences}),
      });
      console.log(res);
      // Set a success message
      setSuccessMessage('Preferences saved successfully!');
      // Optionally, clear the success message after a few seconds
      // setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    preferences,
    availableDataTypes,
    handleCheckboxChange,
    savePreferences,
    loading,
    error,
    successMessage,
  };
};
